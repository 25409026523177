// TODO: this is a hack, correct CSS resets should be provided by PlatformProvider
import styled, { css, keyframes } from "styled-components";
import { Layout, LayoutItem, Button, LayoutProps } from "@audi/audi-ui-react";

export const BlockSpan = styled.span`
  display: block;
`;
BlockSpan.displayName = "BlockSpan";

export const StyledPictureContainer = styled(LayoutItem)`
  position: relative;
  padding-bottom: 150%;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding-bottom: 50%;
  }
`;

export const StyledPicture = styled.picture`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const StyledImg = styled.img`
  width: 100%;
`;
StyledImg.displayName = "StyledImg";

interface CustomLayoutProps extends LayoutProps {
  spacingOption: string;
}

export const StyledHighlightedCarTeaser = styled(Layout)<CustomLayoutProps>`
  margin: 160px 12%;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin: 200px 8% 200px 4%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin: 200px 4%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    margin: 260px 12%;
  }
  ${(props) => {
    if (props.spacingOption === "small") {
      return css`
        margin: 60px 12%;

        @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
          margin: 48px 8% 48px 4%;
        }

        @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
          margin: 60px 4%;
        }

        @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
          margin: 80px 12%;
        }

        @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
          margin: 100px 12%;
        }
      `;
    }
  }}
`;
StyledHighlightedCarTeaser.displayName = "HighlightedCarTeaser";

export const StyledLayoutItemText = styled(LayoutItem)`
  margin-block-start: var(${(props) => props.theme.responsive.spacing.xxl});
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    align-self: center;
    margin-block-start: unset;
    margin-inline-start: var(${(props) => props.theme.responsive.spacing.xxl});
  }
`;
StyledLayoutItemText.displayName = "StyledLayoutItemText";

const basicBackgroundColor = "#f2f2f2";

const skeletonGradient = `
  linear-gradient(to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(252, 252, 252, .7),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
`;

const gradientKeyframes = keyframes`
  0% {background-position:100% 0%}
  100% {background-position:0% 0%}
`;

const cssAnimation = css`
  background: ${skeletonGradient};
  background-size: 400%;
  animation-name: ${gradientKeyframes};
  animation-duration: 1.5s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const SkeletonBone = styled.span`
  background-color: ${basicBackgroundColor};
  ::before {
    width: 100%;
    height: 100%;
    display: inline-block;
    content: "\\00A0";
    ${cssAnimation}
  }
`;

interface SkeletonBoneTypoProps {
  boneWidth?: number;
}

export const SkeletonBoneTypo = styled(SkeletonBone)`
  ::before {
    width: ${(props: SkeletonBoneTypoProps) =>
      props.boneWidth ? props.boneWidth : 100}%;
  }
`;

export const SkeletonBonePicture = styled(SkeletonBone)`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const SkeletonBoneButton = styled(Button)`
  background-color: ${basicBackgroundColor} !important;
  border: 0;
  cursor: auto !important;
  ${cssAnimation}
  ::before {
    width: 100%;
    height: 100%;
    display: inline-block;
    content: "\\00A0";
  }
`;
