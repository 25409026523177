import * as React from "react";
import { ButtonGroup, Text } from "@audi/audi-ui-react";
import {
  StyledPictureContainer,
  StyledHighlightedCarTeaser,
  StyledLayoutItemText,
  SkeletonBonePicture,
  SkeletonBoneTypo,
  SkeletonBoneButton,
} from "./styles";

export const HighlightedCarTeaserSkeleton: React.FunctionComponent = () => {
  return (
    <>
      <StyledHighlightedCarTeaser
        spacingOption={"large"}
        direction={{ xs: "column", m: "row" }}
      >
        <StyledPictureContainer basis="50%" shrink="0">
          <SkeletonBonePicture />
        </StyledPictureContainer>
        <StyledLayoutItemText grow="1" align="stretch">
          <Text as="h2" variant="order1" spaceStackEnd="m">
            <SkeletonBoneTypo boneWidth={70} />
          </Text>
          <Text as="p" variant="copy2" spaceStackEnd="s">
            <SkeletonBoneTypo />
            <SkeletonBoneTypo boneWidth={80} />
          </Text>
          <ButtonGroup variant="block-buttons" spaceStackStart="xxl">
            <SkeletonBoneButton variant="primary" />
          </ButtonGroup>
        </StyledLayoutItemText>
      </StyledHighlightedCarTeaser>
    </>
  );
};
