import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useState,
  SetStateAction,
} from "react";

interface EditModeVariantContextState {
  readonly editModeVariant?: string;
}

interface EditModeVariantContext {
  editModeVariant: string | undefined;
  setEditModeVariant: Dispatch<SetStateAction<string | undefined>>;
}

export const EditModeVariantContext = createContext<EditModeVariantContext>({
  editModeVariant: undefined,
  setEditModeVariant: () => undefined,
});

export function EditModeContextProvider({
  children,
  editModeVariant: variant,
}: PropsWithChildren<EditModeVariantContextState>): JSX.Element {
  const [editModeVariant, setEditModeVariant] = useState(variant);

  return (
    <EditModeVariantContext.Provider
      value={{
        editModeVariant,
        setEditModeVariant,
      }}
    >
      {children}
    </EditModeVariantContext.Provider>
  );
}

export const useEditModeVariant = (): [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>
] => {
  const { editModeVariant, setEditModeVariant } = useContext(
    EditModeVariantContext
  );
  return [editModeVariant, setEditModeVariant];
};
