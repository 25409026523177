import {
  Variant,
  ImageProps as ContentImageProps,
  SpacingOption,
} from "../EditorContentTypes";
import {
  renderTextWithFootnotesReferences,
  cleanupEmissionConsumptionMarkup,
  getImageUrls,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";
import { ConsumptionsAndEmissions } from "@volkswagen-onehub/audi-vue-formatter-service";

import {
  WltpProps,
  HighlightedCarTeaserProps,
  ImageProps,
} from "../component/ComponentTypes";

const mapToImage = ({
  alternativeText,
  imageDesktop: { assetValue: desktopAssetValue },
  imageMobile: { assetValue: mobileAssetValue },
}: ContentImageProps): ImageProps => {
  return {
    alternativeText,
    imageDesktop: getImageUrls(desktopAssetValue)[0],
    imageMobile: getImageUrls(mobileAssetValue)[0],
  };
};

const mapToWltpProp = ({
  formattedConsumption,
  formattedEmission,
}: ConsumptionsAndEmissions): WltpProps | undefined => {
  if (formattedConsumption && formattedEmission) {
    return {
      formattedConsumption: renderTextWithFootnotesReferences(
        cleanupEmissionConsumptionMarkup(formattedConsumption)
      ),
      formattedEmission: renderTextWithFootnotesReferences(
        cleanupEmissionConsumptionMarkup(formattedEmission)
      ),
    };
  }
};

const mapToWltpProps = (
  consumptionsAndEmission: ConsumptionsAndEmissions[]
) => {
  return consumptionsAndEmission
    .map(mapToWltpProp)
    .filter((wltpProp) => wltpProp !== undefined) as WltpProps[];
};

const mapAdditionalLegalText = (additionalText?: string) => {
  if (additionalText) {
    return renderTextWithFootnotesReferences(additionalText);
  }
};

export const mapToTeaserProps = (
  {
    headline,
    legalData: { additionalText },
    image,
    links: [primaryLink, secondaryLink],
    carIdentifiers,
  }: Variant,
  consumptionsAndEmission: ConsumptionsAndEmissions[],
  spacingOption: SpacingOption
): HighlightedCarTeaserProps => {
  return {
    additionalLegalText: mapAdditionalLegalText(additionalText),
    wltpData: mapToWltpProps(consumptionsAndEmission),
    image: mapToImage(image),
    primaryLink,
    secondaryLink,
    carIdentifiers,
    headline: renderTextWithFootnotesReferences(headline),
    spacingOption,
  };
};
