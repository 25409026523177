import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import {
  useContent,
  useInViewEffect,
  transformToTrackingText,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

import { EditModeVariantSelector } from "./component/editModeVariantSelector";
import * as pkg from "../package.json";
import { SSRState, CSRState, ContentState } from ".";
import { Content, Variant } from "./EditorContentTypes";
import { useUseCase, useRenderMode, useLogger, Context } from "./context";
import { useTrackingManager } from "./context/useTrackingManager";
import { HighlightedCarTeaserSkeleton } from "./component/skeleton";
import { HighlightedCarTeaser } from "./component";
import { EditModeVariantSelectorProps } from "./component/ComponentTypes";
import { useEditModeVariant } from "./context/useEditModeVariant";
import { getEditVariant } from "./utils/stateUtils";

const getImageToTrack = (desktop: string, mobile: string) => {
  return window.innerWidth > 768 ? desktop : mobile;
};

export interface VariantWithIsPersonalization extends Variant {
  readonly isPersonalized: boolean;
}

export interface AsyncFeatureAppProps {
  readonly ssrState: SSRState;
  readonly csrState: CSRState;
  readonly createState: (content: Content) => Promise<ContentState>;
}

export const AsyncFeatureApp: React.FC<AsyncFeatureAppProps> = ({
  ssrState,
  csrState,
  createState,
}: AsyncFeatureAppProps) => {
  const initialRender = useRef(true);
  const useCase = useUseCase();
  const content = useContent<Content>();
  const logger = useLogger();
  const { vueFormatterService, localeService } = useContext(Context);

  const trackingManager = useTrackingManager();
  const [state, setState] = useState<ContentState | "skeleton" | null>(() => {
    if (ssrState === "skeleton") {
      // on the server
      return "skeleton";
    }
    if (typeof ssrState === "object") {
      // on the server
      return ssrState;
    }
    if (typeof csrState === "object") {
      // on the client
      return csrState;
    }
    // on the client
    return null;
  });

  const [editModeVariantsProps, setEditModeVariantsProps] =
    useState<EditModeVariantSelectorProps>({ contentIds: [] });
  const [editModeVariant] = useEditModeVariant();
  const [editMode] = useState(useRenderMode());

  useEffect(() => {
    trackingManager.ready(pkg.version);
  }, []);

  useEffect(() => {
    if (content) {
      if (editMode) {
        const contentIds = [
          "default",
          ...content.personalizedVariants.map((variant) => variant.contentId),
        ];

        setEditModeVariantsProps({ contentIds: contentIds });
      }

      if (editModeVariant) {
        (async () => {
          const editModeContent = await getEditVariant(
            content,
            editModeVariant,
            vueFormatterService,
            localeService,
            logger
          );

          try {
            setState(editModeContent);
          } catch ({ message }) {
            logger?.error(message);
          }
        })();
      }
    }
  }, [content, editModeVariant]);

  useEffect(() => {
    let mounted = true;
    const updateTeaserProps = (state: ContentState) => {
      mounted && setState(state);
    };
    if (!state && typeof csrState === "function") {
      // init
      csrState().then(updateTeaserProps);
    } else if (content && !initialRender.current) {
      // live update
      createState(content).then(updateTeaserProps);
    }
    initialRender.current = false;
    return () => {
      mounted = false;
    };
  }, [content]);

  useInViewEffect(() => {
    if (state && typeof state === "object") {
      trackingManager.impression(
        transformToTrackingText(state.selectedVariant.headline),
        getImageToTrack(
          state.teaserProps.image.imageDesktop,
          state.teaserProps.image.imageMobile
        ),
        state.selectedVariant.isPersonalized,
        useCase,
        state.personalizationType,
        state.personalizationInfo?.trackParam,
        state.personalizationInfo?.iterationId,
        state.selectedVariant.carIdentifiers.modelShortcode,
        state.selectedVariant.carIdentifiers.carlineGroup
      );
    }
  });

  const linkTracking = useCallback(
    (href: string, text: string, clickId: string): void => {
      if (state && typeof state === "object") {
        trackingManager.click(
          transformToTrackingText(state.selectedVariant.headline),
          getImageToTrack(
            state.teaserProps.image.imageDesktop,
            state.teaserProps.image.imageMobile
          ),
          href,
          text,
          clickId,
          state.selectedVariant.isPersonalized,
          useCase,
          state.personalizationType,
          state.personalizationInfo?.trackParam,
          state.personalizationInfo?.iterationId,
          state.teaserProps.carIdentifiers.modelShortcode,
          state.teaserProps.carIdentifiers.carlineGroup
        );
      }
    },
    [state]
  );

  if (!state) {
    return null;
  }

  return (
    <>
      {state === "skeleton" && <HighlightedCarTeaserSkeleton />}
      {!!editMode && <EditModeVariantSelector {...editModeVariantsProps} />}
      {state !== "skeleton" && (
        <HighlightedCarTeaser
          {...state.teaserProps}
          linkTracking={linkTracking}
        />
      )}
    </>
  );
};
