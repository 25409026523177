import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TabGroup, Tab, Text } from "@audi/audi-ui-react";
import { EditModeVariantSelectorProps } from "./ComponentTypes";
import { useEditModeVariant } from "../context/useEditModeVariant";

const EditModeNote = styled.div`
  border: 1px solid red;
`;

export const EditModeVariantSelector: React.FunctionComponent<
  EditModeVariantSelectorProps
> = ({ contentIds }: EditModeVariantSelectorProps) => {
  const [previewVariant, setPreviewVariant] = useEditModeVariant();

  const [state, setState] = useState(previewVariant || "default");

  useEffect(() => {
    setPreviewVariant(state);
  }, [state]);

  return (
    <>
      <EditModeNote>
        <Text weight="bold">
          Use the tabs to preview all the content variants. The tabs are not
          part of the Feature App and will not be displayed in the live
          environment.
        </Text>
        <TabGroup id="tab-group__basic" selected={state} onSelect={setState}>
          {contentIds.map((contentId) => {
            return (
              <Tab key={contentId} id={contentId}>
                {contentId}
              </Tab>
            );
          })}
        </TabGroup>
      </EditModeNote>
    </>
  );
};
