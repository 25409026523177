/* eslint-disable react/display-name */
import React from "react";
import { ReactFeatureApp } from "@feature-hub/react";
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from "@feature-hub/core";
import { AsyncSsrManagerV1 } from "@feature-hub/async-ssr-manager";
import { SerializedStateManagerV1 } from "@feature-hub/serialized-state-manager";
import {
  IPersonalizationServiceV1,
  IPersonalizationInfo,
} from "@volkswagen-onehub/audi-personalization-service";
import { ITrackingServiceV1 } from "@volkswagen-onehub/audi-tracking-service";
import { ContentServiceV1 } from "@volkswagen-onehub/audi-content-service";
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from "@volkswagen-onehub/audi-footnote-reference-service";
import {
  ContentContextProvider,
  FootnoteContextProvider,
  InViewContextProvider,
} from "@volkswagen-onehub/audi-etron-gt-utils-feature-app";

import { AsyncFeatureApp, VariantWithIsPersonalization } from "./feature-app";
import { VueFormatterServiceInterfaceV1 } from "@volkswagen-onehub/audi-vue-formatter-service";
import { GfaLocaleServiceV1 } from "@volkswagen-onehub/gfa-locale-service";
import { RenderModeServiceV1 } from "@volkswagen-onehub/audi-render-mode-service";

import { EditModeContextProvider } from "./context/useEditModeVariant";
import { ContextProvider } from "./context";
import { HighlightedCarTeaserProps } from "./component/ComponentTypes";

import {
  createSSRState,
  deserializeSSRState,
  getStateCreator,
} from "./utils/stateUtils";
import { AudiPlatformProvider } from "@audi/audi-ui-react";
import { PersonalizationType } from "./EditorContentTypes";

export interface Dependencies extends FeatureServices {
  readonly "s2:logger"?: Logger;
  readonly "audi-personalization-service": IPersonalizationServiceV1;
  readonly "audi-tracking-service"?: ITrackingServiceV1;
  readonly "audi-content-service": ContentServiceV1;
  readonly "audi-footnote-reference-service": AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly "dbad:audi-vue-formatter-service": VueFormatterServiceInterfaceV1;
  readonly "gfa:locale-service": GfaLocaleServiceV1;
  readonly "s2:async-ssr-manager"?: AsyncSsrManagerV1;
  readonly "s2:serialized-state-manager"?: SerializedStateManagerV1;
  readonly "audi-render-mode-service": RenderModeServiceV1;
}

export interface ContentState {
  readonly selectedVariant: VariantWithIsPersonalization;
  readonly teaserProps: HighlightedCarTeaserProps;
  readonly personalizationInfo?: IPersonalizationInfo;
  readonly personalizationType: PersonalizationType;
}

export type SSRState = ContentState | "skeleton" | "loading";
export type CSRState = ContentState | (() => Promise<ContentState>) | "loading";

const HighlightedCarTeaserFeatureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies
> = {
  dependencies: {
    externals: {
      "@audi/audi-ui-react": "^1.4.0",
      "@feature-hub/react": "^2.7.0",
      react: "^16.13.1",
      "react-dom": "^16.13.1",
      "styled-components": "^5.1.1",
    },
    featureServices: {
      "audi-content-service": "^1.0.0",
      "audi-personalization-service": "^1.0.0",
      "dbad:audi-vue-formatter-service": "1.0.0",
      "gfa:locale-service": "^1.0.0",
    },
  },
  optionalDependencies: {
    featureServices: {
      "s2:logger": "^1.0.0",
      "s2:async-ssr-manager": "^1.0.0",
      "s2:serialized-state-manager": "^1.0.0",
      "audi-tracking-service": "^1.0.0",
      "audi-footnote-reference-service": "3.0.0",
      "audi-render-mode-service": "^1.0.0",
    },
  },

  create: ({ featureAppId, featureServices }) => {
    const {
      "audi-content-service": contentService,
      "audi-tracking-service": trackingService,
      "s2:logger": logger,
      "audi-footnote-reference-service": footnoteReferenceServiceScopeManager,
      "audi-personalization-service": personalizationService,
      "s2:async-ssr-manager": asyncSsrManager,
      "s2:serialized-state-manager": serializedStateManager,
      "dbad:audi-vue-formatter-service": vueFormatterService,
      "gfa:locale-service": localeService,
    } = featureServices;
    const useCase = "highlighted-car-teaser";

    if (trackingService) {
      // set feature app name as early as possible
      trackingService.featureAppName = "audi-highlighted-car-teaser";
    }

    const content = contentService.getContent();
    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();
    const createState = getStateCreator(
      useCase,
      featureAppId,
      personalizationService,
      vueFormatterService,
      localeService,
      logger
    );
    let ssrState: SSRState = "loading";
    let csrState: CSRState = "loading";
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          if (content) {
            ssrState = await createSSRState(
              useCase,
              featureAppId,
              content,
              personalizationService,
              vueFormatterService,
              localeService,
              logger
            );
            serializedStateManager?.register(() => JSON.stringify(ssrState));
          }
        })()
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();

      if (!serializedState || serializedState === `"skeleton"`) {
        csrState = () => createState(content);
      } else {
        csrState = deserializeSSRState(serializedState);
      }
    }

    const render = () => {
      return (
        <AudiPlatformProvider>
          <InViewContextProvider logger={logger}>
            <ContentContextProvider contentService={contentService}>
              <ContextProvider
                featureServices={featureServices}
                useCase={useCase}
              >
                <FootnoteContextProvider
                  footnoteReferenceService={footnoteReferenceService}
                >
                  <div
                    data-fefa-custom-id={footnoteReferenceService.getConsumerId()}
                  >
                    <EditModeContextProvider>
                      <AsyncFeatureApp
                        ssrState={ssrState}
                        csrState={csrState}
                        createState={createState}
                      />
                    </EditModeContextProvider>
                  </div>
                </FootnoteContextProvider>
              </ContextProvider>
            </ContentContextProvider>
          </InViewContextProvider>
        </AudiPlatformProvider>
      );
    };

    return {
      render,
    };
  },
};

export default HighlightedCarTeaserFeatureAppDefinition;
